import { SvgIcon, TileComponent } from "../../components";
import contactImg from "./contactImg.png";
import "./Contact.scss";

function Contact() {
  return (
    <div className="app-contact-container" id="contact">
      <h2 className="app-contact-title">Contact us</h2>
      <div className="contact-areabox">
        <img src={contactImg} alt="contact img" className="app-contact-image" />
        <TileComponent baseclassname={"contact-tile-container"} noBackground>
          <span className="contact-tile-book-appointment">Book your appointment and get the best cleaning services you ever had</span>
          <div className="contact-boxes">
            <SvgIcon logoName={{ locationLogo: true }} size={{ mediumIcon: true }} />
            <a className="contact-box-location" href="https://maps.app.goo.gl/Rddivc1yBSoUnDD48" target="_blank" rel="noreferrer">50 N 8th st Hawthrone NJ</a>
          </div>
          <span>Give us a call and services will be booked on your location</span>
          <div className="contact-boxes">
            <SvgIcon logoName={{ callLogo: true }} size={{ mediumIcon: true }} />
            <a className="contact-box-number" href="tel:+19738511807" target="_blank" rel="noreferrer">+1(973)-851-1807</a>
          </div>
        </TileComponent>
      </div>
    </div>
  )
}

export default Contact;