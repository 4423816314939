export const headerLinks = [
  {
    link: "Home",
    hashes: "#home"
  },
  {
    link: "Services",
    hashes: "#services"
  },
  {
    link: "About",
    hashes: "#about"
  },
  {
    link: "Contact Us",
    hashes: "#contact"
  },
]