import { serviceDetails } from "./utils";
import { PriceCard, SvgIcon, TileComponent } from "../../components";
import "./Services.scss";
import { Icon } from "semantic-ui-react";

function Services() {

  const renderServiceCard = (item) => (
    <PriceCard {...item} />
  )

  const renderPriceDescription = (item, index) => {
    return (
      <div className="pricecard-list-render">
        <Icon name="check circle" />
        <span className="pricecard-description-service-list">{item}</span>
      </div>
    )
  }

  return (
    <div className="app-service-container" id="services">
      <h2 className="services-title">Services</h2>
      <div className="price-card-container">
        {serviceDetails.map(renderServiceCard)}
        <TileComponent noBackground baseclassname={"pricecard-description-tile-container"}>
          <span className="cleaning-facility-service-title">Leena Cleaning Services have many facilities to provide</span>
          <span className="pricecard-description-service-title">Services like</span>
          <div className="pricecard-description-container">
            <div className="pricecard-description-lists">
              {serviceDetails[0].facilityArray.map(renderPriceDescription)}
            </div>
            <SvgIcon baseClassname={"maidicon"} logoName={{ maidImage: true }} size={{ xxxLarge: true }} />
          </div>
          <span className="pricecard-book-your-service">Cost of the service may vary according to house size</span>
          <span className="pricecard-book-price">Price according to house & rooms</span>
        </TileComponent>
      </div>
    </div>
  )
}

export default Services;