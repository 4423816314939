import { ReactComponent as HouseIcon } from "./Icon/houseIcon.svg";
import { ReactComponent as VacuumIcon } from "./Icon/vacuumIcon.svg";
import { ReactComponent as SprayIcon } from "./Icon/sprayIcon.svg";
import { ReactComponent as BroomIcon } from "./Icon/broomIcon.svg";
import { ReactComponent as MaidImage } from "./Icon/maidcleaning.svg";
import {ReactComponent as CallLogo} from "./Icon/callLogo.svg";
import {ReactComponent as LocationLogo} from "./Icon/locationLogo.svg";
import {ReactComponent as PlusLogo} from "./Icon/plusLogo.svg";
import {ReactComponent as MinusLogo} from "./Icon/minusLogo.svg";

function IconRef({
  houseIcon,
  vacuumIcon,
  sprayIcon,
  broomIcon,
  maidImage,
  callLogo,
  locationLogo,
  plusLogo,
  minusLogo
}) {
  return (
    <>
      {houseIcon && <HouseIcon />}
      {vacuumIcon && <VacuumIcon />}
      {sprayIcon && <SprayIcon />}
      {broomIcon && <BroomIcon />}
      {maidImage && <MaidImage />}
      {callLogo && <CallLogo />}
      {locationLogo && <LocationLogo />}
      {plusLogo && <PlusLogo />}
      {minusLogo && <MinusLogo />}
    </>
  )
}

export default IconRef;