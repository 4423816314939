export const footerSocialLinks = [
  {
    socialMedia: "Instagram",
    iconName: "instagram",
    link: "https://www.instagram.com/lina.castillo.316"
  },
  // {
  //   socialMedia: "Facebook",
  //   iconName: "facebook",
  //   link: ""
  // },
  // {
  //   socialMedia: "Twitter",
  //   iconName: "twitter",
  //   link: ""
  // },
  // {
  //   socialMedia: "",
  //   link: ""
  // },
]