import { Icon } from "semantic-ui-react";
import CustomButton from "../CustomButton/CustomButton";
import "./PriceCard.scss";

function PriceCard({
  cardTitle,
  priceAmount,
  menuName,
  menuDescription,
  facilityArray,
}) {

  const renderFacility = (item) => (
    <div>
      <Icon name="check circle" />
      <span className="pricecard-facilitylist">{item}</span>
    </div>
  )

  return (
    <div className="pricecard-container">
      <div className="pricecard-title">{cardTitle}</div>
      <div className="pricecard-ratebox">
        {/* <span className="price-rate">Starts from</span> */}
        {/* <span className="price-rate">{priceAmount}</span> */}
        <span className="pricecard-menuname">{menuName}</span>
      </div>
      <div className="pricecard-facility-container">
        <span className="pricecard-menudescription">{menuDescription}</span>
        {facilityArray?.map(renderFacility)}
        <div className="pricecard-button-container">
          <CustomButton
            blueGradient4
            buttonText={"BOOK NOW"}
            handleClick={()=>{window.open("tel:+19738511807", "_blank");}}
            baseclassname={"subscribe-button"}
            textClassName="subscribe-button-text"
          />
        </div>
      </div>
    </div>
  )
}

export default PriceCard;