import './Menubar.scss';

function Menubar({ pos, show, headerLinks, handleCloseSidebar }) {

  const isNavbar = pos === 'header';
  const isSidebar = pos === 'sidebar';

  const renderHeaderLinks = ({ link, hashes }, index) => (
    <a
      key={`renderHeaderLink-index${index}`}
      href={hashes}
      className='header-links'
      onClick={handleCloseSidebar}
    >
      {link}
    </a>
  )

  console.log("showsidebar", show);

  return (
    <>
      {isNavbar &&
        <div className="header-area">
          {headerLinks.map(renderHeaderLinks)}
        </div>
      }
      {isSidebar && show &&
        <div className={`${pos}-container`}>
          <div className={`${pos}`}>
            {headerLinks.map(renderHeaderLinks)}
          </div>
        </div>
      }
    </>
  )
}

export default Menubar;