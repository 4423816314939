import classNames from "classnames";
import IconRef from "./IconRef";
import "./SvgIcon.scss";


function SvgIcon({
  logoName: {
    houseIcon,
    vacuumIcon,
    sprayIcon,
    broomIcon,
    maidImage,
    callLogo,
    locationLogo,
    plusLogo,
    minusLogo
  },
  size: {
    mini,
    tiny,
    small,
    mediumIcon,
    large,
    twoXlarge,
    threeXLarge,
    xxxLarge
  },
  handleClick,
  baseClassname,
  spinLogo
}) {
  return (
    <div className={classNames([baseClassname],{
      "mini-icon": mini,
      "tiny-icon": tiny,
      "small-icon": small,
      'medium-icon': mediumIcon,
      "large-icon": large,
      'two-x-large-icon': twoXlarge,
      "three-x-large-icon": threeXLarge,
      "xxx-large-icon": xxxLarge,
      "spin-logo": spinLogo
    })} onClick={handleClick}>
      <IconRef 
        houseIcon={houseIcon} 
        vacuumIcon={vacuumIcon}
        sprayIcon={sprayIcon}
        broomIcon={broomIcon}
        maidImage={maidImage}
        callLogo={callLogo}
        locationLogo={locationLogo}
        plusLogo={plusLogo}
        minusLogo={minusLogo}
      />
    </div>
  )
}

export default SvgIcon;
