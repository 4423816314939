
import { SvgIcon, TileComponent } from '../../components';
import specializeImage from "./specialize.png";
import { specializeFeature } from './utils';
import './Specialize.scss';

function Specialize() {

  const renderSpecializeFeature = ({ featureName, featureIcon }, index) => {
    console.log(featureName, featureIcon);
    return (
      <div className='specialize-feature' key={`renderFeatures-index-${index}`}>
        <span className='specialize-featurename'>{featureName}</span>
        <SvgIcon logoName={featureIcon} size={{ threeXLarge: true }} />
      </div>
    )
  }

  return (
    <div className='specialize-container'>
      <TileComponent baseclassname={"specialize-areabox"}>
        <h2 className='specialized-title'>Specialized in</h2>
        <div className="specialize-feature-container">
          {specializeFeature.map(renderSpecializeFeature)}
          {/* <img src={specializeImage} alt="specialize img" className='specialize-image'/> */}
        </div>
      </TileComponent>
    </div>
  )
}

export default Specialize