import { Analytics } from "@vercel/analytics/react";
import { useState } from "react";
import { Footer, Header, MenuBar } from "./components";
import { Home, About, Services, Contact, Specialize } from "./containers";
import { headerLinks } from "./components/Header/utils";
import "./App.scss"

function App() {
  const [showSidebar, setShowSidebar] = useState(false);

  const handleToggleSidebar = () => {
    setShowSidebar((prev) => !prev);
  }

  const handlecloseSidebar = () => {
    setShowSidebar(false);
  }

  return (
    <div className="app-home-container">
      <Header handleOpenMenu={handleToggleSidebar} />
      <div className="app-outlet-container">
        <Home />
        <Specialize />
        <Services />
        <About />
        <Contact />
        <Footer />
        <MenuBar
          headerLinks={headerLinks}
          pos={'sidebar'}
          show={showSidebar}
          handleCloseSidebar={handlecloseSidebar}
        />
      </div>
      <Analytics />
    </div>
  );
}

export default App;
