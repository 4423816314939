import { headerLinks } from './utils';
import logo from "../../assets/logo1.png"
import Menubar from '../Menubar/Menubar';
import CustomButton from '../CustomButton/CustomButton';
import { Icon } from 'semantic-ui-react';
import './Header.scss';

function Header({ handleOpenMenu }) {

  return (
    <div className='header'>
      <div className="header-logo-container">
        <img src={logo} alt="logo-img" className='header-logo' />
        <span className='header-title'>Leena's Cleaning Services</span>
      </div>
      <Menubar pos={'header'} headerLinks={headerLinks} />
      <CustomButton
        pillShape
        blueTheme
        noPadding
        noBackground
        handleClick={handleOpenMenu}
        baseclassname={"header-toggle-menu-button"}
      >
        <Icon name="bars" className='header-menu-button' />
      </CustomButton>
    </div>
  )
}

export default Header