import { Icon } from 'semantic-ui-react';
import SvgIcon from '../SvgIcon/SvgIcon';
import { footerSocialLinks } from './utils';
import './Footer.scss';

function Footer() {
  const date = new Date();
  const dateYear = date.getFullYear();
  const renderFooterSocials = (item, index) => {
    const { socialMedia, iconName, link } = item;
    return (
      <a href={link} target='_blank' rel='noreferrer' className='footer-social-link'>
        <Icon className='icons-socials' name={iconName} />
        <span className='social-icon-text'>{socialMedia}</span>
      </a>
    )
  }


  return (
    <div className='footer-container'>
      <div className="footer-socialmedia-container">
        <span className='footer-social-title'>Follow us on</span>
        <div className="footer-social-link-container">
          {footerSocialLinks.map(renderFooterSocials)}
        </div>
      </div>
      <div className="footer-contacts-container">
        <div className='footer-contactbox'>
          <span>Leena’s Cleaning Services</span>
          <div className='footer-owner-contact'>
            <SvgIcon logoName={{ callLogo: true }} size={{ small: true }} />
            <a href="tel:+1(973)851-1807" className='footer-contact-text'>+1(973)851-1807</a>
          </div>
          <div className='footer-owner-contact'>
            <SvgIcon logoName={{ locationLogo: true }} size={{ small: true }} />
            <a className='footer-contact-text' href="https://maps.app.goo.gl/Rddivc1yBSoUnDD48" target="_blank" rel="noreferrer">50 N 8th St Hawthorne NJ</a>
          </div>
        </div>
        <div className='footer-contactbox'>
          <span>©{dateYear} All Rights Reserved </span>
          <span>Made by DevPeak Innovations</span>
          <span>Developed by <a href="http://dhanur350.github.io/My_Portfolio" target='_blank' rel='noreferrer' className='footer-contact-dev-text'>Dhanur</a></span>
        </div>
      </div>
    </div>
  )
}

export default Footer