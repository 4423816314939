export const specializeFeature = [
  {
    featureName: "HouseKeeping",
    featureIcon: {
      houseIcon: true
    }
  },
  {
    featureName: "Deep Cleaning",
    featureIcon: {
      vacuumIcon: true
    }
  },
  {
    featureName: "Windows Cleaning",
    featureIcon: {
      sprayIcon: true
    }
  },
  {
    featureName: "& more",
    featureIcon: {
      broomIcon: true
    }
  },
]