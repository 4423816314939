import classNames from 'classnames'
import { Button } from 'semantic-ui-react'
import './CustomButton.scss';

function CustomButton({
  baseclassname,
  textClassName,
  disabled,
  loading,
  pillShape,
  noPadding,
  noBackground,
  round,
  defaultButton,
  whiteButton,
  whiteBoxShadow,
  blueTheme,
  blueGradient,
  blueGradient2,
  blueGradient4,
  buttonText,
  children,
  handleClick
}) {
  return (
    <div className={classNames(["custom-button-container", baseclassname], {
      "pill-shape": pillShape,
      "round-shape": round,
      "default-button": defaultButton,
      "white-theme": whiteButton,
      "white-box-shadow": whiteBoxShadow,
      "blue-theme": blueTheme,
      "blue-gradient": blueGradient,
      "blue-gradient-2": blueGradient2,
      "blue-gradient-4": blueGradient4,
      "no-padding": noPadding,
      "no-background": noBackground
    })}>
      <Button
        className='custom-button'
        disabled={disabled}
        loading={loading}
        onClick={handleClick}
      >
        {buttonText && <div className={textClassName}>{buttonText}</div>}
        {children}
      </Button>
    </div>
  )
}

export default CustomButton