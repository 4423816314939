export const serviceCharge = "$35.00"

export const serviceDetails = [
  {
    cardTitle: "BASIC",
    priceAmount: `${serviceCharge}/hour`,
    menuName: "Leena Cleaning Service Menu",
    menuDescription: "Facilities we can provide",
    facilityArray: [
      "House Cleaning",
      "Office Cleaning",
      "Deep Cleaning",
      "Cleaning of newly built or remodeled houses",
      "Washing and folding clothes",
    ]
  },
]