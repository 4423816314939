import classNames from "classnames";
import "./TileComponent.scss";

function TileComponent({ baseclassname, noBackground, handleClick, children }) {
  return (
    <div className={classNames(["tile-container", baseclassname],{
      "no-background-tile": noBackground
    })} onClick={handleClick}>
      {children}
    </div>
  )
}

export default TileComponent;