import { CustomButton } from '../../components'
import './Home.scss';

function Home() {

  return (
    <div className="app-home-area" id="home">
      <div className="app-header-container">
        <div className='app-home-title-container'>
          <div className='home-title-1'>
            <h2 className='app-title'>Looking for a</h2>
            <h2 className='app-title'>best cleaning company?</h2>
          </div>
          <div className='home-title-2'>
            <h2 className='app-title'>Present's you the best cleaning services</h2>
            <h1 className='app-title home-title-3'>Leena's Cleaning Services</h1>
          </div>
        </div>
        <div className="app-header-button-containers">
          <CustomButton
            round
            blueGradient2
            whiteBoxShadow
            baseclassname={"app-header-button"}
            textClassName={"app-header-button-text"}
          >
            <a href="#contact">Get in Touch</a>
          </CustomButton>
          <CustomButton
            round
            blueGradient2
            whiteBoxShadow
            baseclassname={"app-header-button"}
            textClassName={"app-header-button-text"}
          >
            <a href="#services">Know more about us</a>
          </CustomButton>
        </div>
      </div>

    </div>
  )
}

export default Home;