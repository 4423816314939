import { useState } from 'react';
import { SvgIcon, TileComponent } from "../../components";
import aboutImg from "./aboutimg.png";
import './About.scss';

function About() {
  const [toggleTile, setToggletile] = useState(false);

  const handleToggleTile = () => {
    setToggletile(!toggleTile);
  }

  return (
    <div className="app-about-area" id="about">
      <h2 className='app-about-title'>About us</h2>
      <TileComponent noBackground handleClick={handleToggleTile} baseclassname={"app-about-tile-container"}>
        <div className='about-tile-question-container'>
          <h4 className='about-title-question'>How I founded the company</h4>
          {toggleTile ?
            <SvgIcon baseClassname={"about-plus-logo-icon"} logoName={{ minusLogo: true }} size={{ mediumIcon: true }} handleClick={handleToggleTile} />
            :
            <SvgIcon baseClassname={"about-plus-logo-icon"} logoName={{ plusLogo: true }} size={{ mediumIcon: true }} handleClick={handleToggleTile} />}
        </div>
        {toggleTile &&
          <div className='about-tile-answer-container'>
            <div className='about-answer-box'>
              <span className='about-tile-answer-text'>During the period of the COVID-19 pandemic the work was scarce day by day, I got sick with the virus being pregnant, I was looking for a job and I didn't find anything. </span>
              <span className='about-tile-answer-text'>One day a friend told me "Leena you can clean my house is very small it has 3 bedrooms 2 bathrooms the kitchen the living room and the basement I answered the call for the lack of money for the rent and what was coming,</span>
              <span className='about-tile-answer-text'>A couple of months later I was able to meet the needs of many people making their need my passion so Leena's cleaning service's is born</span>
            </div>
            <img src={aboutImg} alt="" className='about-tile-image' />
          </div>}
      </TileComponent>
    </div>
  )
}

export default About